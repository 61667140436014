import { css, Global } from "@emotion/core"
import "normalize.css"
import React from "react"
import { Helmet } from "react-helmet"

const title = "Erik Wahlström"

const AppFrame = props => {
  return (
    <>
      <Global
        styles={css({
          html: {
            boxSizing: "border-box",
            fontFamily: "'Montserrat', sans-serif",
            WebkitFontSmoothing: "antialiased",
          },
          body: {
            margin: 0,
            padding: 0,
          },
          p: {
            fontFamily: "'Karla', sans-serif",
            fontSize: "18px",
            lineHeight: "1.3em",
          },
          h1: {
            letterSpacing: "-0.06em",
            lineHeight: "0.9em",
          },
          a: {
            color: "#000",
          },
        })}
      />
      <Helmet
        {...{ title }}
        htmlAttributes={{ lang: "sv" }}
        meta={[
          { charset: "UTF-8" },
          { name: "description", content: "Personal portfolio" },
          { name: "keywords", content: "HTML,CSS,JavaScript" },
          { name: "author", content: "Erik Wahlström" },
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1, shrink-to-fit=no",
          },
          <link
            href="https://fonts.googleapis.com/css?family=Karla|Montserrat&display=swap"
            rel="stylesheet"
          ></link>,
        ]}
      />
      {props.children}
    </>
  )
}

export default AppFrame
