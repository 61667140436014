import React from "react"
import facepaint from "facepaint"
import CV from "../pdf/erik_resume.pdf"
import profilePic from "../img/profile_pic.jpg"
import { useSpring, animated } from "react-spring"

const mq = facepaint(["@media(min-width: 668px)", "@media(min-width: 1024px)"])

const FirstBox = () => {
  const fade = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(0,0px,0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0,25px,0)",
    },
    config: { duration: 200 },
    delay: 300,
  })
  const fade2 = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(0,0px,0)",
      position: "relative",
      zIndex: 1,
    },
    to: {
      opacity: 1,
      transform: "translate3d(0,20px,0)",
    },
    config: { duration: 200 },
    delay: 500,
  })
  const fade3 = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(0,0px,0)",
    },
    to: {
      opacity: 1,
      transform: "translate3d(0,20px,0)",
    },
    config: { duration: 500 },
    delay: 800,
  })
  console.log(fade)
  return (
    <>
      <div>
        <animated.h1
          style={fade}
          css={mq({
            margin: 0,
            padding: ["30px 0 0 0", "50px 0 0 0", "0 0 0 0"],
            fontSize: ["28vw", "17vw", "17vw"],
          })}
        >
          HELLO.
        </animated.h1>
        <animated.h1
          style={fade2}
          css={mq({
            margin: ["30px 0 50px 0", "0", "0"],
            padding: ["0 20px 0 0", "0 20px 0 0", "0 20px 0 0"],
            fontSize: ["28vw", "17vw", "17vw"],
            textAlign: ["left", "right", "right"],
            width: ["50%", "auto", "auto"],
          })}
        >
          I'M ERIK
        </animated.h1>
        <animated.div
          style={fade3}
          css={mq({
            display: "grid",
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
            gridGap: "30px",
          })}
        >
          <div
            css={mq({
              float: "right",
              padding: [
                "0 0.5em 0 0.5em",
                "0 0.5em 0 0.5em",
                "0 0.5em 0 1.5em",
              ],
            })}
          >
            <h2
              css={mq({
                fontSize: ["25px", "25px", "30px"],
                lineHeight: 1.3,
              })}
            >
              I'm a Frontend developer from Sweden with a keen interest in
              modern web development. What are you waiting for? Tell me what I
              can do for you! Let's make business or just be friends! :)
            </h2>
            <ul css={{ margin: "50px 0 50px 0", padding: "0 0 0 0.5em" }}>
              <li css={{ listStyle: "none", marginBottom: "30px" }}>
                <a
                  css={mq({
                    borderBottom: "8px solid #e3e3e3",
                    fontSize: "25px",
                    fontStyle: "italic",
                    marginBottom: "10px",
                    textDecoration: "none",
                    letterSpacing: "2px",
                    position: "relative",
                    fontWeight: "bold",
                    padding: "1rem 0 0.5rem 0",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      bottom: "-8px",
                      left: 0,
                      width: ["100%", "0%", "0%"],
                      borderBottom: "8px solid #0055ff",
                      transition: "0.4s",
                    },
                    "&:hover::after ": {
                      width: "100%",
                    },
                  })}
                  target="_blank"
                  href="https://www.linkedin.com/in/erik-wahlstr%C3%B6m-03870614b/"
                >
                  LinkedIn
                </a>
              </li>
              <li css={{ listStyle: "none", marginBottom: "30px" }}>
                <a
                  css={mq({
                    borderBottom: "8px solid #e3e3e3",
                    fontSize: "25px",
                    fontStyle: "italic",
                    marginBottom: "10px",
                    textDecoration: "none",
                    letterSpacing: "2px",
                    position: "relative",
                    fontWeight: "bold",
                    padding: "1rem 0 0.5rem 0",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      bottom: "-8px",
                      left: 0,
                      width: ["100%", "0%", "0%"],
                      borderBottom: "8px solid #0055ff",
                      transition: "0.4s",
                    },
                    "&:hover::after ": {
                      width: "100%",
                    },
                  })}
                  target="_blank"
                  href="https://github.com/erikwaahlstrom"
                >
                  GitHub
                </a>
              </li>
              <li css={{ listStyle: "none", marginBottom: "30px" }}>
                <a
                  css={mq({
                    borderBottom: "8px solid #e3e3e3",
                    fontSize: "25px",
                    fontStyle: "italic",
                    marginBottom: "10px",
                    textDecoration: "none",
                    letterSpacing: "2px",
                    position: "relative",
                    fontWeight: "bold",
                    padding: "1rem 0 0.5rem 0",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      bottom: "-8px",
                      left: 0,
                      width: ["100%", "0%", "0%"],
                      borderBottom: "8px solid #0055ff",
                      transition: "0.4s",
                    },
                    "&:hover::after ": {
                      width: "100%",
                    },
                  })}
                  target="_blank"
                  href="mailto:erikwaahlstrom@gmail.com"
                >
                  E-mail
                </a>
              </li>
              <li css={{ listStyle: "none", marginTop: "30px" }}>
                <a
                  css={mq({
                    borderBottom: "8px solid #e3e3e3",
                    fontSize: "25px",
                    fontStyle: "italic",
                    marginBottom: "10px",
                    textDecoration: "none",
                    letterSpacing: "2px",
                    position: "relative",
                    fontWeight: "bold",
                    padding: "1rem 0 0.5rem 0",
                    "&::after": {
                      content: "''",
                      position: "absolute",
                      bottom: "-8px",
                      left: 0,
                      width: ["100%", "0%", "0%"],
                      borderBottom: "8px solid #0055ff",
                      transition: "0.4s",
                    },
                    "&:hover::after ": {
                      width: "100%",
                    },
                  })}
                  target="_blank"
                  href={CV}
                >
                  Resume (.pdf)
                </a>
              </li>
            </ul>
          </div>
          <img
            css={mq({
              width: "100%",
              marginTop: ["0px", "0px", "-80px"],
              float: "left",
            })}
            src={profilePic}
          ></img>
        </animated.div>
      </div>
    </>
  )
}

export default FirstBox
