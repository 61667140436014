import React from "react"
import facepaint from "facepaint"

const mq = facepaint(["@media(min-width: 668px)", "@media(min-width: 1024px)"])

const TimeLine = () => {
  return (
    <>
      <div
        css={mq({
          clear: "both",
          maxWidth: "700px",
          display: "grid",
          margin: "0 auto 50px auto",
          padding: ["0 0.5em 0 0.5em", "0 0.5em 0 0.5em", "80px 0.5em 0 0.5em"],
        })}
      >
        <h1
          css={mq({
            margin: ["100px 0 30px 0", "80px 0 30px 0", "80px 0 30px 0"],
            fontSize: ["28vw", "17vw", "17vw"],
          })}
        >
          MY <br /> STORY
        </h1>
        <ul
          css={mq({
            lineHeight: "1.4em",
            listStyle: "none",
            margin: 0,
            padding: ["0.5em", "0.5em", 0],
          })}
        >
          <li
            css={{
              paddingLeft: "40px",
              paddingBottom: "80px",
              position: "relative",
            }}
          >
            <div
              css={{
                fontSize: "12px",
                fontWeight: 700,
                letterSpacing: "3px",
                margin: "0 0 0.5em 0",
                textTransform: "uppercase",
                whiteSpace: "nowrap",
              }}
            >
              <span>August 2014 - June 2017</span>
            </div>
            <div
              css={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                width: "15px",
                "&::before": {
                  background: "#0055ff",
                  border: "3px solid transparent",
                  borderRadius: "100%",
                  content: "''",
                  display: "block",
                  height: "20px",
                  width: "20px",
                  position: "absolute",
                  top: "4px",
                  transition:
                    "background 0.3s ease-in-out, border 0.3s ease-in-out",
                },
                "&::after": {
                  content: "''",
                  width: "3px",
                  background: "#ccd5db",
                  display: "block",
                  position: "absolute",
                  top: "35px",
                  bottom: 0,
                  left: "11px",
                },
              }}
            ></div>
            <div>
              <h3 css={mq({ fontSize: ["22px", "26px", "30px"] })}>
                Halmstad University
              </h3>
              <p css={mq({ fontSize: ["14px", "16px", "18px"] })}>
                Started my studies in Web Design in Halmstad, Sweden.
              </p>
            </div>
          </li>
          <li
            css={{
              paddingLeft: "40px",
              paddingBottom: "80px",
              position: "relative",
            }}
          >
            <div
              css={{
                fontSize: "12px",
                fontWeight: 700,
                letterSpacing: "3px",
                margin: "0 0 0.5em 0",
                textTransform: "uppercase",
                whiteSpace: "nowrap",
              }}
            >
              <span>November 2017 - June 2019</span>
            </div>
            <div
              css={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                width: "15px",
                "&::before": {
                  background: "#0055ff",
                  border: "3px solid transparent",
                  borderRadius: "100%",
                  content: "''",
                  display: "block",
                  height: "20px",
                  width: "20px",
                  position: "absolute",
                  top: "4px",
                  transition:
                    "background 0.3s ease-in-out, border 0.3s ease-in-out",
                },
                "&::after": {
                  content: "''",
                  width: "3px",
                  background: "#ccd5db",
                  display: "block",
                  position: "absolute",
                  top: "35px",
                  bottom: 0,
                  left: "11px",
                },
              }}
            ></div>
            <div>
              <h3 css={mq({ fontSize: ["22px", "26px", "30px"] })}>
                Nicknamed
              </h3>
              <p css={mq({ fontSize: ["14px", "16px", "18px"] })}>
                Frontend developer position. Developing fintech and web
                solutions for Svea Ekonomi.
              </p>
            </div>
          </li>
          <li
            css={{
              paddingLeft: "40px",
              paddingBottom: "80px",
              position: "relative",
            }}
          >
            <div
              css={{
                fontSize: "12px",
                fontWeight: 700,
                letterSpacing: "3px",
                margin: "0 0 0.5em 0",
                textTransform: "uppercase",
                whiteSpace: "nowrap",
              }}
            >
              <span>August 2019 - Ongoing</span>
            </div>
            <div
              css={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                width: "15px",
                "&::before": {
                  background: "#0055ff",
                  border: "3px solid transparent",
                  borderRadius: "100%",
                  content: "''",
                  display: "block",
                  height: "20px",
                  width: "20px",
                  position: "absolute",
                  top: "4px",
                  transition:
                    "background 0.3s ease-in-out, border 0.3s ease-in-out",
                },
                "&::after": {
                  content: "''",
                  width: "3px",
                  background: "#ccd5db",
                  display: "block",
                  position: "absolute",
                  top: "35px",
                  bottom: 0,
                  left: "11px",
                },
              }}
            ></div>
            <div>
              <h3 css={mq({ fontSize: ["22px", "26px", "30px"] })}>Sigma</h3>
              <p css={mq({ fontSize: ["14px", "16px", "18px"] })}>
                Frontend developer consultant for Sigma IT.
              </p>
            </div>
          </li>
          <li
            css={{
              paddingLeft: "40px",
              paddingBottom: "80px",
              position: "relative",
              ":last-child": {
                paddingBottom: 0,
                content: "none",
              },
            }}
          >
            <div
              css={{
                fontSize: "12px",
                fontWeight: 700,
                letterSpacing: "3px",
                margin: "0 0 0.5em 0",
                textTransform: "uppercase",
                whiteSpace: "nowrap",
              }}
            >
              {" "}
              <span>The adventure that awaits...</span>
            </div>
            <div
              css={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                width: "15px",
                "&::before": {
                  background: "#0055ff",
                  border: "3px solid transparent",
                  borderRadius: "100%",
                  content: "''",
                  display: "block",
                  height: "20px",
                  width: "20px",
                  position: "absolute",
                  top: "4px",
                  transition:
                    "background 0.3s ease-in-out, border 0.3s ease-in-out",
                },
                "&::after": {
                  content: "''",
                  width: "3px",
                  background: "#ccd5db",
                  display: "block",
                  position: "absolute",
                  top: "35px",
                  bottom: 0,
                  left: "11px",
                },
              }}
            ></div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default TimeLine
