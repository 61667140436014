import React from "react"
import AppFrame from "../components/AppFrame"
import FirstBox from "../components/FirstBox"
import TimeLine from "../components/TimeLine"

export default () => (
  <AppFrame>
    <FirstBox />
    <TimeLine />
  </AppFrame>
)
